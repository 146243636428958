<template>
	<div class="page">
		<div class="block30"></div>
		<div class="huaxue_team_detail relative w1239" v-if="dataobj">
			<div class="data_con">
				<div class="left">
					<img :src="dataobj.imgUrl || require('../assets/static/logo.png')"/>
				</div>
				<div class="right">
					<div class="title fontsize30">{{dataobj.title}}<span class="fontsize20" v-if="dataobj.realTimeType">{{dataobj.realTimeType.name}}</span></div>
					<div class="text1 fontsize20">擅长领域：<span>{{dataobj.description}}</span></div>
					<div class="text">
						<div class="fontsize20" v-html="dataobj.contents"></div>
					</div>
				</div>
			</div>
		</div>
		<div class="block60"></div>
	</div>
</template>

<script>
import {mapState} from 'vuex'
export default {
	data() {
		return {
			uid:0,//详情
			dataobj:null,
		};
	},
	mounted: function() {
		if(this.$route.query&&this.$route.query.uid){
			this.uid = parseInt(this.$route.query.uid)
			this.getDataDetail()
		}else{
			this.$util.routerPath("/404")
		}
	},
	computed: {
		...mapState(['userInfo', 'isLogin']),
	},
	methods: {
		//获取详情
		getDataDetail() {
			var _this = this;
			var params = {
				id:this.uid
			};
			this.$http.post('frontEnd/index/getRealTimeInfoByID', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var dataobj = res.data;
					if(dataobj){
						_this.dataobj = dataobj
					}else{
						_this.$util.routerPath("/404")
					}
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		
	}
};
</script>
<style lang="scss">

</style>
